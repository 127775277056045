/* main script file */
/* call your functions here */

import LazyLoad from 'vanilla-lazyload';
import { header } from './components/header.js';
// import { flickity } from './components/slider.js';
import { search } from './components/search.js';

if (document.querySelector('.lazy')) {
	var ll = new LazyLoad();
}

if (document.querySelector('.header')) {
	header();
}

if (document.querySelector('.search')) {
	search();
}

// if (document.querySelector('.slider')) {
// 	flickity();
// }
