/* global search component */

/**
 *	To do : 
 	1. clean and remove redundancies
	2. rename
 *
 */

/**
 *	also :
	- reset search when changing cat
	- ajouter un bouton "fermer"
	- smooth animation (?) - option
 *
 */

const debug = false;

// function toggleSearchItems(event) {
// 	const keys = document.querySelectorAll('.search__key');

// 	if (event.target.classList.contains('is-active')) {
// 		if (debug) console.log(`reset...`);

// 		/* reset search items */
// 		deactivate(keys);
// 	} else {
// 		if (debug) console.log(`reset before...`);

// 		/* reset search items */
// 		deactivate(keys);

// 		if (debug) console.log(`key: ${event.target.innerText}`);

// 		/* show this search items */
// 		event.target.classList.add('is-active');

// 		/* show the selected group */
// 		/* ... */
// 		let cat = document.querySelector(`#${event.target.id}.search__cat`);
// 		let cats = document.querySelectorAll('.search__cat');

// 		if (cat.classList.contains('is-active')) {
// 			cats.forEach((cat) => {
// 				cat.classList.remove('is-active');
// 			});
// 		} else {
// 			cats.forEach((cat) => {
// 				cat.classList.remove('is-active');
// 			});

// 			cat.classList.add('is-active');
// 		}
// 	}
// }

// function filter(event) {
// 	const items = document.querySelectorAll('.search__item');
// 	const blocks = document.querySelectorAll('.block');

// 	if (event.target.classList.contains('is-active')) {
// 		if (debug) console.log(`reset...`);

// 		/* reset search items */
// 		deactivate(items);

// 		/* show all block */
// 		showAll(blocks);
// 	} else {
// 		if (debug) console.log(`reset before...`);

// 		/* reset search items */
// 		deactivate(items);

// 		/* hide all block */
// 		hideAll(blocks);

// 		if (debug) console.log(`searching: ${event.target.innerText}`);

// 		/* filter this */
// 		showThis(event.target, blocks);
// 	}
// }

// function deactivate(array) {
// 	for (let i = 0; i < array.length; i++) {
// 		const item = array[i];
// 		item.classList.remove('is-active');
// 	}
// }

// function showAll(array) {
// 	for (let i = 0; i < array.length; i++) {
// 		const item = array[i];
// 		item.classList.remove('hide-item');
// 	}
// }

// function hideAll(array) {
// 	for (let i = 0; i < array.length; i++) {
// 		const item = array[i];
// 		item.classList.add('hide-item');
// 	}
// }

// function showThis(needle, haystack) {
// 	needle.classList.add('is-active');

// 	for (let i = 0; i < haystack.length; i++) {
// 		const hay = haystack[i];
// 		let string = hay.dataset.search;
// 		let identifiers = string.split(',');

// 		for (let i = 0; i < identifiers.length; i++) {
// 			const id = identifiers[i];

// 			// if (debug) {
// 			// 	console.log(`item${n} id: ${id}`);
// 			// }

// 			if (id === needle.id) {
// 				// if (debug) {
// 				// 	console.log(`match found : ${id}`);
// 				// }

// 				hay.classList.remove('hide-item');
// 			}
// 		}
// 	}
// }

// const search = document.querySelector('.search');
// const keys = document.querySelectorAll('.search__key');

// function toggleSearchBar() {
// 	const search = document.querySelector('.search');

// 	if (search.classList.contains('is-active')) {
// 		search.classList.remove('is-active');
// 	} else {
// 		search.classList.add('is-active');
// 	}
// }

function openSearchBar(event) {
	const search = document.querySelector('.search');
	if (!search.classList.contains('is-active')) {
		search.classList.add('is-active');
	}
	// expandSearchItem(event.target);

	// toggleSearchBar();
}

function closeSearchBar(event) {
	const search = document.querySelector('.search');
	if (search.classList.contains('is-active')) {
		search.classList.remove('is-active');
	}

	// toggleSearchBar();
}

function search() {
	const search = document.querySelector('.search');
	const keys = document.querySelectorAll('.search__key');
	const close = document.querySelector('.search__close');

	keys.forEach((key) => {
		key.addEventListener('click', openSearchBar);
	});

	if (close) {
		close.addEventListener('click', closeSearchBar);
	}

	// const items = document.querySelectorAll('.search__item');
	// items.forEach((item) => {
	// 	item.addEventListener('click', filter);
	// });
}

export { search };
