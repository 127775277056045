/* header functions */

import { gsap } from 'gsap';

function toggleMenu() {
	const header = document.querySelector('.header');
	const backdrop = document.querySelector('.backdrop');

	gsap.defaults({
		ease: 'none',
	});

	if (header.classList.contains('is-active')) {
		/* scroll */
		document.body.classList.remove('menu-open');

		/* menu close */
		header.classList.remove('is-active');

		gsap.set(backdrop, {
			autoAlpha: 0,
			clearProps: true,
		});
	} else {
		/* no-scroll */
		document.body.classList.add('menu-open');

		/* menu open */
		header.classList.add('is-active');

		gsap.to(backdrop, {
			autoAlpha: 1,
			duration: 0.3,
		});
	}
}

function addClassOnScroll() {
	const header = document.querySelector('.header');

	if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
		header.classList.add('is-scrolled');
	} else {
		header.classList.remove('is-scrolled');
	}
}

function header() {
	const btn = document.querySelector('.menu-btn');
	const backdrop = document.querySelector('.backdrop');

	btn.addEventListener('click', toggleMenu);
	backdrop.addEventListener('click', toggleMenu);

	// window.addEventListener('scroll', addClassOnScroll);
}

export { header };
